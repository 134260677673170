<template>
  <div class="clock-in-out">
    <div class="viewEdit">
      <!-- Resource managemane lable & year-->
      <div class="card-filter-section">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <p class="filter-title font-weight-bold">
              Resource Management (Actual)
            </p>
          </div>
          <!--calendar view-->
          <div class="calendar mt-0 justify-content-center align-items-center calendarBg">
            <div
              id="date-select"
              class="date-select mr-0"
            >
              <div class="date-dropdown">
                <VueDatePicker
                  v-model="date"
                  :type="tab == 'weekly' ? 'quarter' : 'month'"
                  no-header
                />
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <!-- Tab  -->
            <div class="card-header-edit">
              <div class="card-header-first mt-0">
                <div class="button-group resource-plan mr-1">
                  <b-button-group>
                    <b-button
                      :class="[tab == 'daily' ? 'btn-role active' : 'btn-role']"
                      variant="secondary"
                      @click="tab = 'daily'"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        class="calendar-icon"
                        size="15"
                      />
                      Monthly</b-button>
                    <b-button
                      :class="[
                        tab == 'weekly' ? 'btn-role active' : 'btn-role',
                      ]"
                      variant="secondary"
                      @click="tab = 'weekly'"
                    ><img :src="Image">Quarterly
                    </b-button>
                  </b-button-group>
                  <Help
                    v-show="$store.state.app.userManualList"
                    code="Actual"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--filter start-->
      <div class="card-filter-section">
        <b-row>
          <b-col
            cols="12"
            lg="12"
            class="
                  d-block d-lg-flex
                  align-items-center
                  justify-content-start
                  flex-wrap
                "
          >
            <p class="filter-title my-50 ">
              Filters:
            </p>

            <div class="my-50">
              <custom-dropdown
                v-model="is_active"
                :options="isActiveList"
                :search-box="false"
                :hide-close="true"
                label="Active"
                icon="EditIcon"
              />
            </div>

            <div class="w-lg-100 my-50">
              <custom-dropdown
                v-model="searchUser"
                :options="filterUserOptions"
                multiple
                label="Members"
                icon="EditIcon"
                @search="updateSearch"
              />
            </div>

            <div
              v-if="userInfo && userInfo.role == 'SA'"
              class="w-lg-100 my-50"
            >
              <custom-dropdown
                v-model="manage_by"
                :options="manage_by_users"
                label="Managed by"
                icon="EditIcon"
              />
            </div>
            <div class="w-lg-100 my-50">
              <custom-dropdown
                v-model="reporting_to"
                :options="reporting_to_users"
                label="Report To"
                class="resource-plan"
                icon="EditIcon"
              />
            </div>
            <div
              v-if="userInfo.role == 'SA'"
              class="my-50"
            >
              <custom-dropdown
                v-model="group_id"
                :options="groups"
                label="Group"
                icon="EditIcon"
              />
            </div>
            <div class="w-lg-100 my-50">
              <custom-dropdown
                v-model="skills"
                :options="userSkills"
                multiple
                label="Skills"
                icon="EditIcon"
              />
            </div>
            <div
              v-if="tab == 'daily'"
              class="w-lg-100 mr-1 my-50"
            >
              <custom-dropdown
                v-model="allocation"
                :options="allocations"
                :search-box="false"
                label="Allocation"
                icon="EditIcon"
              />
            </div>
            <div class="w-lg-100 mr-1 my-50">
              <option-drop-down v-model="perPage" />
            </div>
            <div>
              <button
                class="apply-filter mr-1 filterRedesign"
                @click="applyFilter()"
              >
                Apply Filter
              </button>
              <button
                class="apply-filter-clear filterRedesign"
                @click="clearFilter()"
              >
                Clear
              </button>
            </div>
          </b-col>

        </b-row>
      </div>
      <!--filter end-->

      <!-- monthly View -->
      <div v-if="tab == 'daily' && !loader">

        <div class="report-view skill-report actual-report-table">
          <TableLoader
            v-if="resourceLoader"
            :fields="monthly"
          />
          <b-table
            v-else
            :fields="monthly"
            :items="Object.keys(monthlyItems)"
            class="resource-user-table d-inline-block"
          >
            <template #cell(name)="data">
              <div class="resource-user-data">
                <div
                  :class="monthlyItems[data.item][0].totalHours < 40
                    ? 'available availibility'
                    : monthlyItems[data.item][0].totalHours > 119
                      ? 'not-available availibility '
                      : 'part-available availibility'
                  "
                />

                <div class="resource-user">
                  <div class="d-flex align-items-center">
                    <b-avatar
                      :src="monthlyItems[data.item] &&
                        monthlyItems[data.item].length
                        ? monthlyItems[data.item][0].picture
                        : ''
                      "
                      :text="monthlyItems[data.item] &&
                        monthlyItems[data.item].length
                        ? monthlyItems[data.item][0].full_name
                        : '' | avatarText
                      "
                      size="50"
                    />
                  </div>
                  {{ monthlyItems[data.item][0].full_name }}
                  <div
                    v-b-modal.resource-modal
                    class="resource-user-chart"
                    @click="popIndex = monthlyItems[data.item][0].id, modalDate = date, modalQuarterlyPlanList()"
                  >
                    <feather-icon
                      icon="BarChart2Icon"
                      width="18"
                      height="18"
                      style="color: #a821fb"
                    />
                  </div>
                </div>
              </div>
              <div class="users-tag">
                <span
                  v-for="(primary, index) in monthlyItems[data.item][0]
                    .primary_skills"
                  v-if="monthlyItems &&
                    monthlyItems[data.item] &&
                    monthlyItems[data.item].length &&
                    monthlyItems[data.item][0] &&
                    monthlyItems[data.item][0].primary_skills &&
                    index < 1
                  "
                  :key="index"
                >{{ primary.name }}</span>
                <span
                  v-if="monthlyItems &&
                    monthlyItems[data.item] &&
                    monthlyItems[data.item].length &&
                    monthlyItems[data.item][0] &&
                    monthlyItems[data.item][0].primary_skills &&

                    monthlyItems[data.item][0].primary_skills.length > 2
                  "
                  v-b-tooltip.hover.top="skillsFunction(monthlyItems[data.item][0].primary_skills)"
                > +{{
                  monthlyItems[data.item][0].primary_skills.length - 1 }}</span>
              </div>
            </template>

            <template #cell(Projects)="data">
              <div class="plan-project actual-log">
                <span
                  v-if="monthlyItems[data.item][0] &&
                    monthlyItems[data.item][0].resource_plan &&
                    monthlyItems[data.item][0].resource_plan.length
                  "
                  class="plan-tag"
                >Planned</span>
                <div>
                  <div
                    v-for="(resourcePlan, index) in monthlyItems[data.item][0]
                      .resource_plan"
                    v-if="monthlyItems[data.item][0] &&
                      monthlyItems[data.item][0].resource_plan &&
                      monthlyItems[data.item][0].resource_plan.length
                    "
                    class="resource-user-project"
                  >
                    <div class="resource-project-name">
                      {{ resourcePlan.project.code }}
                    </div>
                    <div class="resource-project-hour">
                      <span>{{
                        resourcePlan.planned_hours ? (resourcePlan.planned_hours / 160
                          * 100).toFixed(2) : 0
                      }}%</span>
                      ({{ resourcePlan.planned_hours }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="actual-project actual-log">
                <span
                  v-if="monthlyItems[data.item][0] &&
                    monthlyItems[data.item][0].resource_plan &&
                    monthlyItems[data.item][0].resource_plan.length
                  "
                  class="plan-tag"
                >Actual</span>
                <div
                  v-for="(resourcePlan, index) in monthlyItems[data.item][0]
                    .resource_plan"
                  v-if="monthlyItems[data.item][0] &&
                    monthlyItems[data.item][0].resource_plan &&
                    monthlyItems[data.item][0].resource_plan.length
                  "
                  :class="(resourcePlan.actual_hours / 60) / resourcePlan.planned_hours * 100 < 91 ? 'part-available' : (resourcePlan.actual_hours / 60) / (resourcePlan.planned_hours) * 100 > 110 ? 'not-available' : 'available'"
                  style="margin-right: 8px"
                >
                  <div class="resource-user-project">
                    <div class="resource-project-name">
                      {{ resourcePlan.project.code }}
                    </div>
                    <div class="resource-project-hour">
                      <span>{{
                        ((resourcePlan.actual_hours / 60) / 160 * 100).toFixed(2)
                      }}%</span>
                      ({{ resourcePlan.actual_hours > 0 ? (resourcePlan.actual_hours / 60).toFixed(2)
                        : 0 }} hrs) </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template #cell(total_hours)="data">
              <div class="resource-user-total">
                <span
                  :class="monthlyItems[data.item][0].totalHours < 40
                    ? 'text-success'
                    : monthlyItems[data.item][0].totalHours > 119
                      ? 'text-danger'
                      : 'text-warning'
                  "
                >

                  {{ monthlyItems[data.item][0] && monthlyItems[data.item][0].totalHours ?
                    monthlyItems[data.item][0].percentage : 0 }} %
                </span> ({{ monthlyItems[data.item][0] && monthlyItems[data.item][0].totalHours ?
                  monthlyItems[data.item][0].totalHours : 0 }} hrs)

              </div>

              <span
                :class="monthlyItems[data.item][0].actualTotal / monthlyItems[data.item][0].totalHours * 100 < 91 ? 'text-warning' : monthlyItems[data.item][0].actualTotal / monthlyItems[data.item][0].totalHours * 100 > 110 ? 'text-danger' : 'text-success'"
              >{{
                monthlyItems[data.item][0].actualTotal / 160 * 100 > 0 ?
                  (monthlyItems[data.item][0].actualTotal / 160 * 100).toFixed(2) : 0 }} %</span>
              <span>({{ (monthlyItems[data.item][0].actualTotal).toFixed(2) }} hrs)</span>
            </template>
          </b-table>
        </div>
      </div>

      <!-- Quaterly View-->
      <div v-if="tab == 'weekly' && !loader">
        <div class="report-view skill-report align-th-table">
          <TableLoader
            v-if="resourceLoader"
            :fields="quarter"
          />
          <b-table
            v-else
            :fields="quarter"
            :items="Object.keys(quarterItems)"
          >
            <template #cell(name)="data">
              <div class="actual-quarter">
                <div>
                  <div class="resource-user-data">

                    <div class="resource-user">

                      <b-avatar
                        :src="quarterItems[data.item] &&
                          quarterItems[data.item].picture ?
                            quarterItems[data.item].picture

                          : ''
                        "
                        :text="quarterItems[data.item] &&
                          quarterItems[data.item].full_name
                          ? quarterItems[data.item].full_name
                          : '' | avatarText
                        "
                        size="50"
                      />

                      {{ quarterItems[data.item].full_name }}
                      <div
                        v-b-modal.resource-modal
                        class="resource-user-chart"
                        @click="popIndex = quarterItems[data.item].id, userResourcePlanMonth = startDate, modalDate = date, modalQuarterlyPlanList()"
                      >
                        <feather-icon
                          icon="BarChart2Icon"
                          width="18"
                          height="18"
                          style="color: #a821fb"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="users-tag">
                    <span
                      v-for="(primary, index) in quarterItems[data.item]
                        .primary_skills"
                      v-if="quarterItems[data.item] &&
                        quarterItems[data.item].primary_skills && index < 1
                      "
                      :key="index"
                    >{{ primary.name }} </span>

                    <span
                      v-if="quarterItems[data.item] &&
                        quarterItems[data.item].primary_skills &&
                        quarterItems[data.item].primary_skills.length > 1
                      "
                      v-b-tooltip.hover.top="skillsFunction(quarterItems[data.item].primary_skills)"
                    > +{{
                      quarterItems[data.item].primary_skills.length - 1 }}</span>
                  </div>
                </div>
                <div class="planned-tags">
                  <div class="mb-50">
                    Planned
                  </div>
                  <div>Actual</div>
                </div>
              </div>
            </template>

            <template #cell(january)="data">
              <div class="mb-50 planned-data">
                <div class="resource-user-total quarter-hour available">
                  <span class="text-success">{{ (quarterItems[data.item].month[0] / 160 *
                    100).toFixed(2) }}%</span> ({{ quarterItems[data.item].month[0] }} hrs)
                </div>
              </div>
              <div>
                <div
                  :class="quarterItems[data.item].month[0] == 0 ? 'resource-user-total quarter-hour part-available' :
                    (quarterItems[data.item].actualMonth[0] / quarterItems[data.item].month[0] * 100).toFixed(2) < 91 ? 'part-available resource-user-total quarter-hour' :
                    (quarterItems[data.item].actualMonth[0] / quarterItems[data.item].month[0] * 100).toFixed(2) > 110 ? 'not-available resource-user-total quarter-hour' : 'available resource-user-total quarter-hour'
                  "
                >
                  <span
                    :class="quarterItems[data.item].month[0] == 0 ? 'text-warning' :
                      (quarterItems[data.item].actualMonth[0] / quarterItems[data.item].month[0] * 100).toFixed(2) < 91 ? 'text-warning' :
                      (quarterItems[data.item].actualMonth[0] / quarterItems[data.item].month[0] * 100).toFixed(2) > 110 ? 'text-danger' : 'text-success'
                    "
                  >{{ quarterItems[data.item].actualMonth[0] > 0 ? (quarterItems[data.item].actualMonth[0] / 160 *
                    100).toFixed(2) : 0 }} %</span> ({{ quarterItems[data.item].actualMonth[0] }} hrs)
                </div>
              </div>
            </template>

            <template #cell(feb)="data">
              <div class="mb-50 planned-data">
                <div class="resource-user-total quarter-hour available">
                  <span class="text-success">{{ (quarterItems[data.item].month[1] / 160 *
                    100).toFixed(2) }}%</span> ({{ quarterItems[data.item].month[1] }} hrs)
                </div>
              </div>
              <div>
                <div
                  :class="quarterItems[data.item].month[1] == 0 ? 'resource-user-total quarter-hour part-available' :
                    (quarterItems[data.item].actualMonth[1] / quarterItems[data.item].month[1] * 100).toFixed(2) < 91 ? 'part-available resource-user-total quarter-hour' :
                    (quarterItems[data.item].actualMonth[1] / quarterItems[data.item].month[1] * 100).toFixed(2) > 110 ? 'not-available resource-user-total quarter-hour' : 'available resource-user-total quarter-hour'
                  "
                >
                  <span
                    :class="quarterItems[data.item].month[1] == 0 ? 'text-warning' :
                      (quarterItems[data.item].actualMonth[1] / quarterItems[data.item].month[1] * 100).toFixed(2) < 91 ? 'text-warning' :
                      (quarterItems[data.item].actualMonth[1] / quarterItems[data.item].month[1] * 100).toFixed(2) > 110 ? 'text-danger' : 'text-success'
                    "
                  >{{ quarterItems[data.item].actualMonth[1] > 0 ? (quarterItems[data.item].actualMonth[1] / 160 *
                    100).toFixed(2) : 0 }} %</span> ({{ quarterItems[data.item].actualMonth[1] }} hrs)
                </div>
              </div>
            </template>

            <template #cell(march)="data">
              <div class="mb-50 planned-data">
                <div class="resource-user-total quarter-hour available">
                  <span class="text-success">{{ (quarterItems[data.item].month[2] / 160 *
                    100).toFixed(2) }}%</span> ({{ quarterItems[data.item].month[2] }} hrs)
                </div>
              </div>
              <div>
                <div
                  :class="quarterItems[data.item].month[2] == 0 ? 'resource-user-total quarter-hour part-available' :
                    (quarterItems[data.item].actualMonth[2] / quarterItems[data.item].month[2] * 100).toFixed(2) < 91 ? 'part-available resource-user-total quarter-hour' :
                    (quarterItems[data.item].actualMonth[2] / quarterItems[data.item].month[2] * 100).toFixed(2) > 110 ? 'not-available resource-user-total quarter-hour' : 'available resource-user-total quarter-hour'
                  "
                >
                  <span
                    :class="quarterItems[data.item].month[2] == 0 ? 'text-warning' :
                      (quarterItems[data.item].actualMonth[2] / quarterItems[data.item].month[2] * 100).toFixed(2) < 91 ? 'text-warning' :
                      (quarterItems[data.item].actualMonth[2] / quarterItems[data.item].month[2] * 100).toFixed(2) > 110 ? 'text-danger' : 'text-success'
                    "
                  >
                    {{ quarterItems[data.item].actualMonth[2] > 0
                      ? (quarterItems[data.item].actualMonth[2] / 160 * 100).toFixed(2) : 0 }} %</span>
                  ({{ quarterItems[data.item].actualMonth[2] }} hrs)

                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <!--no data found Image -->
      <img
        v-if="totalCount == 0 && !resourceLoader"
        src="@/assets/images/nodatafound/nodata.svg"
        alt="no-data"
        class="no-data-img"
      >

      <div class="pb-1">
        <!-- pagination section -->
        <Pagination
          v-if="totalCount>0 && !resourceLoader"
          :current-page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @currentPage="currentPage = $event"
        />
      </div>
      <!-- pagination section complete here -->
      <b-modal
        id="resource-modal"
        class="resource-modal"
        size="lg"
        centered
      >
        <div
          v-if="userResourcePlanMonthData"
          class="resource-modal-content"
        >
          <div class="resource-profile viewEdit">
            <div class="resource-user-detail">
              <img
                v-if="userResourcePlanMonthData && userResourcePlanMonthData.picture"
                :src="userResourcePlanMonthData && userResourcePlanMonthData.picture ? userResourcePlanMonthData.picture : ''"
              >

              <b-avatar
                v-else
                :text="userResourcePlanMonthData && userResourcePlanMonthData.full_name
                  ? userResourcePlanMonthData.full_name
                  : '' | avatarText
                "
                size="50"
              />

              {{ userResourcePlanMonthData.full_name }}

            </div>
            <div class="resource-user-tags">
              <span
                v-for="(primary, index) in userResourcePlanMonthData.primary_skills"
                :key="index"
              >{{
                primary.name }}</span>
            </div>
            <div class="devider" />
            <div class="calendar mt-0 justify-content-center calendarBg">
              <div
                id="date-select"
                class="date-select"
              >

                <VueDatePicker
                  v-model="modalDate"
                  :type="tab == 'weekly' ? 'quarter' : 'month'"
                  no-header
                />
              </div>
            </div>
            <div class="px-2">
              <apexchart
                ref="chart"
                type="bar"
                height="350"
                :options="userResourcePlanMonthData.chartOptions"
                :series="userResourcePlanMonthData.series"
              />
            </div>
          </div>

          <div>
            <b-table
              v-if="tab == 'daily'"
              :fields="modal"
              :items="userResourcePlanMonthData.resource_plan"
              class="modal-table approvel-table"
            >
              <template #cell(name)="data">
                <div class="resource-user-data">
                  <div class="available availibility" />
                  <div class="resource-user">
                    {{ data.item.project_name }}
                    <span>({{ data.item.project_code }})</span>
                  </div>
                </div>
              </template>

              <template #cell(allocation)="data">
                <div class="resource-user-total quarter-hour available">

                  <span>{{ (data.item.actual_hours / 60 / 160 * 100).toFixed(2) }} /
                    {{ (data.item.planned_hours / 160 * 100).toFixed(2) }} (%)</span>
                </div>
              </template>

              <template #cell(assignment)="data">
                <div class="resource-user-total quarter-hour part-available">
                  {{ (data.item.actual_hours / 60).toFixed(2) }}/ {{ data.item.planned_hours.toFixed(2) }}
                  (hrs)
                </div>
              </template>

              <template #head(name)="data">
                <span class="text-info">{{ data.label.toUpperCase() }}</span>
              </template>
            </b-table>
          </div>
          <p
            v-if="tab == 'daily' && userResourcePlanMonthData.resource_plan && !userResourcePlanMonthData.resource_plan.length"
          >
            No data found
          </p>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { VBToggle, VBTooltip, BBadge } from 'bootstrap-vue'
import * as moment from 'moment/moment'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import ListLoader from '@/components/loaders/timesheet-loader.vue'
import VueApexCharts from 'vue-apexcharts'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

import TableButton from '../../@core/components/buttons/TableButton.vue'

import TableLoader from '../../components/loaders/table-loading.vue'

const date = new Date()

export default {
  name: 'ViewEditHeader',
  components: {

    TableButton,
    TableLoader,
    FeatherIcon,

    customDropdown,
    ListLoader,
    BBadge,
    VBTooltip,
    apexchart: VueApexCharts,
    VueDatePicker,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      popIndex: null,
      Image,
      loader: false,
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      tab: 'daily',
      daily: true,
      weekly: false,
      users: [],
      manage_by_users: [],
      manage_by: null,
      user_id: null,
      weekDate: null,
      searchUser: [],
      year: null,
      years: [],
      searchManageByUser: null,
      userResourcePlanMonth: null,
      userResourcePlanMonthData: null,
      resource_plans: [],
      usersIds: [],
      userSkills: [],
      skills: [],
      group_id: null,
      groups: [],
      rangeDate: null,
      modalLoader: true,
      is_active: 'active',
      modalDate: date,
      date: `${date.getFullYear()}-2`,
      minDate: `${date.getFullYear() - 3}-2`,
      maxDate: `${date.getFullYear() + 3}-2`,
      dateRangeConfig: {
        inline: {
          inline: true,
        },
      },
      startDate: null,
      reporting_to: null,
      reporting_to_users: [],
      endDate: null,
      resourceLoader: false,
      monthly: [
        { key: 'name', label: 'Name' },
        { key: 'Projects', label: 'Projects' },
        { key: 'total_hours', label: 'Total Hours' },
      ],
      monthlyItems: [],
      quarter: [
        { key: 'name', label: 'Name' },
        { key: 'january', label: 'January' },
        { key: 'feb', label: 'February' },
        { key: 'march', label: 'March' },
      ],
      quarterItems: [],
      modal: [
        { key: 'name', label: 'Project Name' },
        { key: 'allocation', label: 'Actual / Plan (%)' },
        { key: 'assignment', label: 'Actual / Plan (hrs)' },
      ],
      modalItems: [],
      series: [{
        name: 'Planned',
        data: [44, 55, 57],
      },
      {
        name: 'Actual',
        data: [76, 85, 101],
      },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          /* added for remove Download button */
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            borderRadius: 8,
            columnWidth: '50%',
          },
        },
        colors: ['#5E5873', '#2178FB', '#C6C6C6'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar'],
        },
        fill: {
          opacity: 1,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },

      },

      allocations: [{
        value: 'available',
        label: 'Available',
      },
      {
        value: 'parttime',
        label: 'Part Time',
      },
      {
        value: 'fulltime',
        label: 'Full Time',
      },
      ],
      allocation: null,
      userSearch: null,
    }
  },

  computed: {
    filterUserOptions() {
      return this.filterUserOptionsFunction(this.users, this.userSearch)
    },
  },
  watch: {
    tab() {
      const start = this.startDate
      const end = this.endDate
      this.startDate = this.lastTabstartDate
        ? this.lastTabstartDate
        : this.startDate
      this.endDate = this.lastTabendDate ? this.lastTabstartDate : this.endDate
      this.lastTabstartDate = start
      this.lastTabendDate = end

      this.popIndex = null
      this.userResourcePlanMonthData = null
      this.totalCount = 0
      if (this.currentPage != 1) {
        this.currentPage = 1
      }
      this.currentMonth()
    },
    currentPage: {
      handler(value) {
        if (value) {
          this.applyFilter()
        }
      },
    },

    perPage() {
      if (this.currentPage === 1) {
        this.applyFilter()
      } else {
        this.currentPage = 1
      }
    },
    date() {
      this.applyFilter()
    },
    modalDate() {
      this.modalQuarterlyPlanList()
    },
  },

  mounted() {
    /*  to remove warning msg in console */
    moment.suppressDeprecationWarnings = true
    this.currentMonth()
    this.filterFunctionCallFromMixin()
  },
  methods: {
    /** update userSearch value */
    updateSearch(data) {
      this.userSearch = data
    },
    /*  filter data */
    async filterFunctionCallFromMixin() {
      /**  role super admin grouplist & manageby list , reporting , primaryskill , userlist  common api calling  */
      if (this.userInfo && this.userInfo.role == 'SA') {
        if (this.$store.state.app.groupList.length == 0) {
          this.groups = await this.getGroupData()
        } else {
          this.groups = this.$store.state.app.groupList
        }

        if (this.$store.state.app.manageByUsersList.length == 0) {
          this.manage_by_users = await this.manageByUserList()
        } else {
          this.manage_by_users = this.$store.state.app.manageByUsersList
        }
      } else {
        this.searchManageByUser = this.userInfo.id
      }

      if (this.$store.state.app.reportingUserList.length == 0) {
        this.reporting_to_users = await this.reportingtoUserList()
      } else {
        this.reporting_to_users = this.$store.state.app.reportingUserList
      }

      if (this.$store.state.app.userSkills.length == 0) {
        this.userSkills = await this.getPrimarySkillData()
      } else {
        this.userSkills = this.$store.state.app.userSkills
      }

      if (this.$store.state.app.usersList.length == 0) {
        this.users = await this.userList()
      } else {
        this.users = this.$store.state.app.usersList
      }

      if (this.userInfo && this.userInfo.role == 'EMP') {
        this.users = this.$store.state.app.usersList.filter(
          element => element.manage_by == this.userInfo.id,
        )
      }
    },
    /* filters */
    applyFilter() {
      if (this.tab === 'daily') {
        this.monthlyPlanList()
      } else {
        this.quarterlyPlanList()
      }
    },

    /*  get start & end date  */
    currentMonth() {
      this.startDate = moment().startOf('month').format('YYYY-MM-DD')
      this.endDate = moment().endOf('month').format('YYYY-MM-DD')
      this.date = this.tab == 'weekly'
        ? `${moment().endOf('month').format('YYYY')}-${moment().quarter()}`
        : moment().endOf('month').format('YYYY-MM')
    },

    /*  resource plans */
    async monthlyPlanList() {
      this.resourceLoader = true
      const input = {
        member: this.searchUser && this.searchUser.length
          ? this.searchUser
          : this.group_id
            ? ''
            : this.usersIds,
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        page: this.currentPage,
        per_page: this.perPage,
        primary_skill_ids: this.skills,
        group_id: this.group_id,
        month: [moment(this.date, 'YYYY-MM').format('MM')],
        year: moment(this.date, 'YYYY-MM').format('YYYY'),
        manage_by: this.manage_by,
        reporting_to: this.reporting_to,
        allocation: this.allocation,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/resource-plan/user-plan-list',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.resource_plan
        this.totalCount = response.data.count

        this.monthlyItems = data.reduce((acc, item) => {
          if (!acc[item.id]) {
            acc[item.id] = []
          }
          acc[item.id].push(item)
          return acc
        }, {})

        /*  to get total hours & percentage */
        Object.keys(this.monthlyItems).forEach(element => {
          let total = 0
          let actualTotal = 0

          if (
            this.monthlyItems[element][0].resource_plan
                        && this.monthlyItems[element][0].resource_plan.length
          ) {
            this.monthlyItems[element][0].resource_plan.forEach(element => {
              total += element.planned_hours ? element.planned_hours : 0
              actualTotal += element.actual_hours ? Number(element.actual_hours) : 0
            })
          }

          this.monthlyItems[element][0].totalHours = total
          this.monthlyItems[element][0].actualTotal = actualTotal / 60
          this.monthlyItems[element][0].percentage = total == 0
            ? 0
            : parseFloat(((total * 100) / 160).toFixed(2)).toString()
        })

        this.resourceLoader = false
      } else {
        this.resourceLoader = false
      }
    },

    /*  resource plans */
    async quarterlyPlanList() {
      this.resourceLoader = true
      const month = moment(this.date, 'YYYY-M').format('M')
      const months = this.getQuarterMonth(month)

      this.quarter = this.generateMonthLabels(months)

      this.resourceLoader = true

      const input = {
        member: this.searchUser && this.searchUser.length
          ? this.searchUser
          : this.group_id
            ? ''
            : [],
        is_active: this.is_active == 'active'
          ? true
          : this.is_active == 'inactive'
            ? false
            : '',
        page: this.currentPage,
        per_page: this.perPage,
        primary_skill_ids: this.skills,
        group_id: this.group_id,
        month: months,
        year: moment(this.date, 'YYYY-M').format('YYYY'),
        manage_by: this.manage_by,
        reporting_to: this.reporting_to,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/resource-plan/plan-quarterly-list',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.resource_plan
        this.totalCount = response.data.count

        this.quarterItems = data

        /*  to get total hours & percentage */
        Object.keys(data).forEach((element, index) => {
          this.quarterItems[element].month = []
          this.quarterItems[element].actualMonth = []

          months.forEach(e => {
            const m = this.quarterItems[element].resource_plan
                                && this.quarterItems[element].resource_plan.length
              ? this.quarterItems[element].resource_plan.filter(
                c => moment(c.month).format('MM') == moment(e).format('MM'),
              )
              : []
            let monthHours = 0
            let actualHours = 0
            if (m && m.length) {
              m.forEach(element => {
                monthHours += element.planned_hours
                actualHours += element.actual_hours / 60 // response in minute so convert into hours
              })
            }

            this.quarterItems[element].month.push(monthHours)
            this.quarterItems[element].actualMonth.push(
              actualHours > 0 ? actualHours.toFixed(2) : 0,
            )
          })
        })
        this.resourceLoader = false
      } else {
        this.resourceLoader = false
      }
    },

    /*  resource plans */
    async modalQuarterlyPlanList() {
      this.modalLoader = true

      const month = this.tab === 'daily'
        ? moment(this.modalDate, 'YYYY-MM').format('MM')
        : moment(this.modalDate, 'YYYY-M').format('M')
      const year = this.tab === 'daily'
        ? moment(this.modalDate, 'YYYY-MM').format('YYYY')
        : moment(this.modalDate, 'YYYY-M').format('YYYY')
      const months = this.tab === 'daily'
        ? [month]
        : this.getQuarterMonth(month)

      this.userResourcePlanData = []

      const input = {
        member: [this.popIndex],
        month: months,
        year,
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/resource-plan/quarterly-details',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.resource_plan
        this.userResourcePlanMonthData = data[0]
        /*  chart data   &  month wise total hours */
        this.userResourcePlanMonthData.series = [
          { name: 'Planned', data: [] },
          { name: 'Actual', data: [] },
        ]
        this.userResourcePlanMonthData.chartOptions = this.chartOptions
        this.userResourcePlanMonthData.chartOptions.xaxis.categories = []
        months.forEach(e => {
          this.chartOptions.xaxis.categories.push(
            moment(e, 'MM').format('MMM'),
          )
          const m = data[0].resource_plan && data[0].resource_plan.length
            ? data[0].resource_plan.filter(
              c => moment(c.month).format('MM') == moment(e).format('MM'),
            )
            : []
          let monthHours = 0
          let actualHours = 0
          if (m && m.length) {
            m.forEach(element => {
              monthHours += element.planned_hours
              actualHours += element.actual_hours / 60 // response in minute so convert into hours
            })
          }
          this.userResourcePlanMonthData.series[0].data.push(
            monthHours.toFixed(2),
          )
          this.userResourcePlanMonthData.series[1].data.push(
            actualHours.toFixed(2),
          )
        })

        this.$refs.chart.updateOptions({
          ...this.chartOptions,
        })

        this.modalLoader = false
      } else {
        this.modalLoader = false
      }
    },
    clearFilter() {
      this.group_id = null
      this.skills = []
      this.searchUser = []
      this.reporting_to = null
      this.manage_by = null
      this.allocation = null
      this.currentPage = 1
      this.perPage = 100
      this.allocation = null
      this.is_active = 'active'
      this.currentMonth()
      this.applyFilter()
    },

  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/viewedit.scss";

.resource-plan {
    .radio-dropdown {
        left: -29px;
    }
}

.actual-report-table {
    th {
        &:nth-child(2) {
            text-align: start;
        }
    }
}

.calendar.calendarBg {
    .date-dropdown {
        background-color: #e9f1ff;
        color: $black-color;
        font-weight: $fw600;
        padding: 6px 10px;
        border-radius: 6px;
        width: 186px;

        &:hover,
        &:active,
        &:focus {
            color: $black-color;
        }
    }

    .vd-picker__input {
        color: $black-color !important;
    }

    .vd-picker__input-icon {
        margin-top: 0 !important;
    }

    .vd-picker__input input {
        padding: 0;
        font-weight: $fw500;
        margin-top: 2px;
    }
}
</style>
